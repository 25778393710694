<template>
  <v-container fluid>
    <div class="mx-4">
      <v-row>
        <v-col>
          <h1 class="secondary--text">{{ $t("rule.title") }}</h1>
        </v-col>
      </v-row>

      <v-row class="pb-0">
        <v-col class="pb-0 px-4" cols="6">
          <v-row>
            <v-col cols="12" md="6" lg="5">
              <v-autocomplete
                :items="ruleTags"
                :return-object="false"
                v-model="ruleFilter.tags"
                deletable-chips
                label="Busque por categoria"
                multiple
                small-chips
                solo
                clearable
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    {{ formatter.getPluralText("outra", ruleFilter.tags) }}
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="5">
              <v-btn
                block
                style="height: 49px"
                large
                dark
                color="primary"
                @click="onClickSearch()"
              >
                <v-icon style="font-size: 17px" class="mr-2">fa-search</v-icon>Pesquisar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pb-0 px-4" cols="6">
          <v-row justify="end">
            <v-col cols="12" md="6" lg="5">
              <v-btn
                block
                style="height: 49px"
                large
                dark
                color="primary"
                @click="createRule()"
              >
                <v-icon style="font-size: 17px" class="mr-2">fa-plus</v-icon>Criar Regra
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="px-4">
          <v-data-table
          :headers="headers"
          :items="rules"
          :loading="loadingRules"
          item-key="id"
          class="elevation-1"
          :page="page"
          :items-per-page="itemsPerPage"
          hide-default-header
          fixed-header
          :footer-props="{
            itemsPerPageOptions: [25, 50, 75, 100],
            'items-per-page-text': 'Registros por página',
          }"
          :height="rules && rules.length >= 10 ? 528 : null"
        >
        <template v-slot:[`header`]="{ props }">
            <thead>
              <tr>
                <th v-for="(header, index) in props.headers" :key="index" :style="{ 'background-color': '#ffffff !important', 'text-align': 'start' }">
                  <label class="label primary--text">
                    {{ header.text }}
                  </label>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:[`item.movementTypes`]="{ item }">
            <span>{{ movementTypeFormater(item.movementTypes) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="textPrimary" dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <TodoListActions :actions="getActionsRulesForItem(item)" />
            </v-menu>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-col class="px-4" cols="12" align="end">
      <v-footer>
        <v-row class="d-flex pt-6 justify-end">
          <v-btn
            x-large
            outlined
            color="textPrimary"
            width="250px"
            @click="onClickCancel()"
          >
            Voltar
          </v-btn>
        </v-row>
      </v-footer>
    </v-col>

    <ExclusionDynamicModal :show="showExclusionDynamicModal" :loadingConfirm="loadingConfirm" @close="closeExclusionDynamicModal" @confirm="confirmExclusionDynamicModal">
      <template slot="header"> Excluir Regra </template>
      <template slot="content">
        Tem certeza que deseja excluir a regra abaixo?
        <v-alert color="background" class="mt-3">
          <span class="title">{{ ruleToBeDeleted.name }}</span>
        </v-alert>
      </template>
    </ExclusionDynamicModal>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import TagService from '@/services-http/sdi/TagService';
import MetaRuleService from '@/services-http/sdi/MetaRuleService';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import ExclusionDynamicModal from '@/components/ExclusionDynamicModal/ExclusionDynamicModal.vue';

export default {
  name: 'GeneralRules',
  components: { TodoListActions, SnackbarCustomize, ExclusionDynamicModal },
  data: () => ({
    loadingRules: false,
    isRHProtegido: false,
    showExclusionDynamicModal: false,
    loadingConfirm: false,

    page: 1,
    totalPages: 0,
    itemsPerPage: 25,

    options: {},
    ruleToBeDeleted: {},
    ruleFilter: { tags: [] },

    ruleTags: [],
    rules: [],
    headers: [
      {
        text: 'Nome da Regra',
        align: 'start',
        value: 'name',
        sortable: false,
      },
      {
        text: 'Mensagem',
        value: 'message',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Tipo de movimentação',
        value: 'movementTypes',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'start',
        sortable: false,
      },
    ],
  }),

  mixins: [
    VerifyRoutesMixin,
  ],

  created() {
    this.options.mounted = true;
    this.formatter = new Formatters();
    this.tagService = new TagService();
    this.metaRuleService = new MetaRuleService();
  },

  mounted() {
    this.loadTags();
    this.loadMetaRules();
  },

  methods: {
    loadTags() {
      this.tagService.FindAll().then((response) => {
        if (response && response.data) {
          this.ruleTags = response.data;
        }
      }).catch((error) => {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar as tags.');
      });
    },
    async loadMetaRules() {
      try {
        this.loadingRules = true;
        const rules = await (this.ruleFilter.tags && this.ruleFilter.tags.length > 0 ? this.loadMetaRulesByTags() : this.loadAllMetaRules());
        this.rules = rules;
      } catch (error) {
        this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar as regras.');
      } finally {
        this.loadingRules = false;
      }
    },
    async loadMetaRulesByTags() {
      const params = new URLSearchParams();
      this.ruleFilter.tags.forEach((tag) => { params.append('tags', tag); });

      const response = await this.metaRuleService.FindByParams(params);
      return response.data;
    },
    async loadAllMetaRules() {
      const response = await this.metaRuleService.FindAll();
      return response.data;
    },
    onClickSearch() {
      this.loadMetaRules();
    },
    createRule() {
      this.redirectRouter('RuleNew');
    },
    movementTypeFormater(mt) {
      let text = '-';
      mt.forEach((mts, index) => {
        if (index === 0) {
          text = this.formatter.formatToTitleCase(mts.name);
        } else {
          text += `, ${this.formatter.formatToTitleCase(mts.name)}`;
        }
      });

      return text;
    },
    getActionsRulesForItem(item) {
      const actions = [
        {
          label: 'Editar',
          icon: 'fa fa-edit',
          color: 'textPrimary',
          condition: item,
          handler: () => this.editRule(item),
        },
        {
          label: 'Copiar',
          icon: 'fa fa-copy',
          color: 'textPrimary',
          condition: item,
          handler: () => this.copyRule(item),
        },
        {
          label: 'Excluir',
          icon: 'fas fa-trash',
          color: 'textPrimary',
          condition: item,
          handler: () => this.openExclusionDynamicModal(item),
        },
      ];

      const sortedActions = this.formatter.sortByProperty(actions, 'label');

      return sortedActions;
    },
    copyRule(rule) {
      this.redirectRouter('CopyRule', { isRHProtegido: this.isRHProtegido }, { copyId: rule.idLastVersion });
    },
    editRule(rule) {
      this.redirectRouter('RuleEdit', { isEdit: true, isRHProtegido: this.isRHProtegido }, { id: rule.idLastVersion });
    },
    openExclusionDynamicModal(rule) {
      this.ruleToBeDeleted = {};
      this.ruleToBeDeleted = rule;
      this.showExclusionDynamicModal = true;
    },
    closeExclusionDynamicModal() {
      this.showExclusionDynamicModal = false;
    },
    confirmExclusionDynamicModal() {
      this.loadingConfirm = true;
      this.metaRuleService.Delete(this.ruleToBeDeleted.id).then(() => {
        this.$refs.SnackbarCustomize.open('success', 'Regra deletada com sucesso');
        this.loadMetaRules();
      }).catch(() => {
        this.$refs.SnackbarCustomize.open('error', 'Erro ao excluir regra. Verifique se está vinculada a outras atividades.');
      }).finally(() => {
        this.loadingConfirm = false;
        this.closeExclusionDynamicModal();
      });
    },
    onClickCancel() {
      this.$router.push({ name: 'HomeAllMenus' });
    },
  },
};
</script>
