/* eslint-disable */
import HttpService from '../HttpService'

export default class BankService {
  constructor() {
    this._httpService = new HttpService('/sdi/meta-rule');
  }

  async FindAll() {
    return await this._httpService.get('');
  }

  async FindByParams(params) {
    return await this._httpService.get('', params);
  }

  async Delete(ruleId) {
    return await this._httpService.delete(`${ruleId}`,);
  }
}
