/* eslint-disable */
import HttpService from '../HttpService'

export default class TagService {
  constructor() {
    this._httpService = new HttpService('/sdi/tag');
  }

  async FindAll() {
    return await this._httpService.get('');
  }
}